import { useEffect } from 'react';
import { useState } from 'react';
import style from './dpe.module.css';

const DPE_COLOR = {
	A: { class: 'mt-[0%]', name: 'A', downloading_class: 'mt-[0%]' },
	B: { class: 'mt-[8.5%]', name: 'B', downloading_class: 'mt-[4%]' },
	C: { class: 'mt-[20.5%]', name: 'C', downloading_class: 'mt-[12%]' },
	D: { class: 'mt-[32.5%]', name: 'D', downloading_class: 'mt-[20%]' },
	E: { class: 'mt-[42%]', name: 'E', downloading_class: 'mt-[28%]' },
	F: { class: 'mt-[55%]', name: 'F', downloading_class: 'mt-[36%]' },
	G: { class: 'mt-[64%]', name: 'G', downloading_class: 'mt-[44%]' }
};

export const Dpe = ({ bien, is_downloading }) => {
	const calculateDPE = () => {
		const consommation = parseInt(bien.diagnostic.dpe_consommation);
		const ges = parseInt(bien.diagnostic.dpe_ges);
		let dpeColor;

		if ((0 <= ges && 6 >= ges) || (consommation <= 69 && 0 <= consommation)) {
			dpeColor = DPE_COLOR.A;
		}
		if (
			(7 <= ges && 10 >= ges) ||
			(consommation <= 109 && 70 <= consommation)
		) {
			dpeColor = DPE_COLOR.B;
		}
		if (
			(11 <= ges && 29 >= ges) ||
			(consommation <= 179 && 110 <= consommation)
		) {
			dpeColor = DPE_COLOR.C;
		}
		if (
			(30 <= ges && 49 >= ges) ||
			(consommation <= 249 && 180 <= consommation)
		) {
			dpeColor = DPE_COLOR.D;
		}
		if (
			(50 <= ges && 69 >= ges) ||
			(consommation <= 329 && 250 <= consommation)
		) {
			dpeColor = DPE_COLOR.E;
		}
		if (
			(70 <= ges && 99 >= ges) ||
			(consommation <= 429 && 330 <= consommation)
		) {
			dpeColor = DPE_COLOR.F;
		}
		if (100 <= ges || 430 <= consommation) {
			dpeColor = DPE_COLOR.G;
		}
		return dpeColor;
	};

	const [currentGes, setCurrentGes] = useState();

	useEffect(() => {
		const ges = calculateDPE();
		setCurrentGes(ges);
	}, []);

	return (
		<>
			{(bien.diagnostic.dpes === 'DPE Vièrge' && (
				<img
					className='w-1/3'
					src='/images/logo_DPE_vierge.png'
					alt='DPE Vierge'
				/>
			)) ||
				(currentGes && (
					<>
						{
							<div
								className='w-full flex items-stretch min-h-[450px]'
								style={{
									backgroundSize: '100%',
									backgroundPosition: '0px 0',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url('/images/DPE/${currentGes.name}.png')`
								}}>
								<div
									className={`${currentGes.downloading_class} ${
										(bien.diagnostic.dpe_ges && 'gap-4') || 'gap-6'
									} pl-1 flex items-start text-[13px] font-bold`}>
									<div>{bien.diagnostic.dpe_consommation}</div>
									<div>{bien.diagnostic.dpe_ges}</div>
								</div>
							</div>
						}
					</>
				))}
		</>
	);
};

const GES_COLOR = {
	A: {
		class: 'mt-[12%] mr-[35%]',
		name: 'A',
		downloading_class: 'mt-[2.5%] mr-[35%]'
	},
	B: {
		class: 'mt-[26%] mr-[32%]',
		name: 'B',
		downloading_class: 'mt-[8%] mr-[30%]'
	},
	C: {
		class: 'mt-[37%] mr-[30%]',
		name: 'C',
		downloading_class: 'mt-[13%] mr-[26%]'
	},
	D: {
		class: 'mt-[50%] mr-[25%]',
		name: 'D',
		downloading_class: 'mt-[18.5%] mr-[23%]'
	},
	E: {
		class: 'mt-[63%] mr-[19.5%]',
		name: 'E',
		downloading_class: 'mt-[24%] mr-[17.5%]'
	},
	F: {
		class: 'mt-[74%] mr-[15.5%]',
		name: 'F',
		downloading_class: 'mt-[29%] mr-[14.5%]'
	},
	G: {
		class: 'mt-[88%] mr-[11.5%]',
		name: 'G',
		downloading_class: 'mt-[35%] mr-[9%]'
	}
};

export const Ges = ({ bien, is_downloading }) => {
	const calculateDPE = () => {
		const ges = parseInt(bien.diagnostic.dpe_ges);
		let dpeColor;
		if (0 <= ges && 6 >= ges) {
			dpeColor = GES_COLOR.A;
		}
		if (7 <= ges && 10 >= ges) {
			dpeColor = GES_COLOR.B;
		}
		if (11 <= ges && 29 >= ges) {
			dpeColor = GES_COLOR.C;
		}
		if (30 <= ges && 49 >= ges) {
			dpeColor = GES_COLOR.D;
		}
		if (50 <= ges && 69 >= ges) {
			dpeColor = GES_COLOR.E;
		}
		if (70 <= ges && 99 >= ges) {
			dpeColor = GES_COLOR.F;
		}
		if (100 <= ges) {
			dpeColor = GES_COLOR.G;
		}

		return dpeColor;
	};

	const [currentGes, setCurrentGes] = useState();

	useEffect(() => {
		const ges = calculateDPE();
		setCurrentGes(ges);
	}, []);

	return (
		<>
			{(bien.diagnostic.dpes === 'DPE Vièrge' && (
				<img
					className='w-1/3'
					src='/images/logo_DPE_vierge.png'
					alt='DPE Vierge'
				/>
			)) ||
				(currentGes && (
					<>
						{
							<div
								className='w-full flex items-stretch min-h-[450px]'
								style={{
									backgroundSize: '100%',
									backgroundPosition: '0px 0',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url('/images/GES/${currentGes.name}.png')`
								}}>
								<div
									className={`${currentGes.downloading_class} ${
										(bien.diagnostic.dpe_ges && 'gap-4') || 'gap-6'
									} pl-1 flex items-start text-[13px] font-bold w-full justify-end`}>
									{bien.diagnostic.dpe_ges}
								</div>
							</div>
						}
					</>
				))}
		</>
	);
};
