import style from './news.module.css';
import { dateFormater } from '../../../helpers/formatter';
import { NewEntity } from './NewEntity';
import { imageAgent } from '../../../helpers/image.source.';

const TEXTE = {
	CREATED: { text: "Ajout d'un(e) nouveau(lle) ", name: 'CREATION' },
	ASSOCIED: { text: 'Association de ', name: 'ASSOCIATION' },
	UPDATED: { text: 'Mise à jour effectué sur  ', name: 'MISE À JOUR' },
	DELETED: { text: 'Suppression du ', name: 'SUPPRESSION' }
};

const COLOR = ['bg-[#2d5c6a]'];

const redirectDetail = (news) => {
	const entity = news.entity.split('\\')[news.entity.split('\\').length - 1];
	const content = JSON.parse(news.content);
	switch (entity) {
		case 'Contact':
			return `/mes-contacts/details-contact/${content.uuid}`;
		case 'Bien':
			return `/mes-biens/details-bien/${content.uuid}`;
		default:
			return '';
	}
};

export const NewsCard = ({ news }) => {
	const entity = news.entity.split('\\')[news.entity.split('\\').length - 1];
	const color = () => {
		switch (news.action.toUpperCase()) {
			case 'DELETED':
				return 'bg-red-700';
			case 'UPDATED':
				if (['Contact', 'Bien', 'User'].includes(entity))
					return 'bg-orange-500';
				break;
			case 'CREATED':
				if (['Contact', 'Bien', 'User'].includes(entity)) return 'bg-green-500';
				else if (entity === 'Estimation') return 'bg-blue-500';
				break;
			case 'ASSOCIED':
				if (['Contact', 'Bien', 'User'].includes(entity))
					return 'bg-yellow-400';
				break;
			default:
				return 'bg-[#2d5c6a]';
		}
	};
	return (
		<div className={style.card__container}>
			<div className={`${style.banner} ${color()}`}>
				{TEXTE[news.action.toUpperCase()]?.name}
			</div>
			<div className={style.card__news}>
				<div className='flex space-x-4'>
					<div className='rounded-full bg-slate-700 h-20 w-20'>
						<img
							src={
								news.agent?.photo_url
									? imageAgent(
											`/${news.agent.agency_id}/${news.agent.photo_url}`
									  )
									: '/images/agents/8380015.jpg'
							}
							alt=''
						/>
					</div>
					<div className='flex-1 space-y-6 py-1'>
						<a href={`${redirectDetail(news)}`} className={style.bg__link}>
							{TEXTE[news.action.toUpperCase()]?.text} {entity} :{' '}
							{<NewEntity entity={entity} news={news} action={news.action} />}
						</a>
						<div className='space-y-3'>
							<div className='grid grid-cols-3 gap-4'>
								<div className={`${style.bg__col} col-span-2`}>
									Agent : {news.agent?.name}
								</div>
								<div className={`${style.bg__col} col-span-1`}>
									Date : {dateFormater(news.created_at)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
