import { useEffect, useState } from 'react';
import { Badge } from '../../components/commons/badge';
import { CardHome } from '../../components/generals/home/card-home';
import { Indicateur } from '../../service/indicateur/Indicateur';
import { GeneralSpinner } from '../../components/commons/general-spinner';
import { useNewsService } from '../../service/news/useNewsService';
import { GlobalNews } from '../../components/generals/news/GlobalNews';

export default function Home() {
	const [indicateur, setIndicateur] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [news, setNews] = useState();

	useEffect(() => {
		setIsLoading(true);
		Promise.all([
			fetchNews(),
			Indicateur.getIndicateur()
				.then((res) => {
					setIndicateur(res.data);
				})
				.catch(() => {})
		]).finally(() => setIsLoading(false));
	}, []);

	const fetchNews = () => {
		useNewsService.fetchAllNews().then((response) => {
			setNews(response.data.data.slice(0, 6));
		});
	};

	return (
		(indicateur && (
			<>
				<div
					className='grid lg:grid-cols-5 sm:grid-cols-1 lg:grid-rows-4 sm:grid-rows-8 gap-1'
					style={{ minHeight: '500px' }}>
					<CardHome className='default-color' rowStart={2} colStart={1}>
						<h1 style={{ fontSize: '30px' }} className='font-medium uppercase'>
							<a href='/ma-pige'>Ma pige</a>
						</h1>
						<div className='flex items-center justify-left h-2/3'>
							<ul className='list-none py-5 sm:py-0'>
								<li className='p-2 text-left'>
									Nouvelle(s) annonce(s) :
									<Badge bgColor={'bg-red-700'} textColor={'text-white'}>
										{indicateur.annonces}
									</Badge>
								</li>
							</ul>
						</div>
					</CardHome>
					<CardHome
						className='blue-color'
						colStart={2}
						rowStart={2}
						photo='/images/background-home/mes_biens.JPG'>
						<h1 style={{ fontSize: '30px' }} className='font-medium uppercase'>
							<a href='/mes-biens'>Mes biens</a>
						</h1>
						<div className='flex items-center justify-left h-2/3'>
							<ul className='list-none'>
								<li className='p-2 text-left'>
									Bien(s) actif(s) : {indicateur.bien_actifs}
								</li>
								<li className='p-2 text-left'>
									Bien(s) inactif(s) : {indicateur.bien_inactifs}
								</li>
								<li className='p-2 text-left'>
									Bien(s) archivé(s) : {indicateur.bien_archives}
								</li>
							</ul>
						</div>
					</CardHome>
					<CardHome className='blue-color'>
						<h1 style={{ fontSize: '30px' }} className='font-medium uppercase'>
							<a href='/mes-contacts'>Mes contacts</a>
						</h1>
						<div className='flex items-center justify-left h-2/3'>
							<ul className='list-none'>
								<li className='p-2 text-left'>
									Contact(s) actif(s) : {indicateur.contact_actifs}
								</li>
								<li className='p-2 text-left'>
									Contact(s) inactif(s) : {indicateur.contact_inactifs}
								</li>
								<li className='p-2 text-left'>
									Contact(s) archivé(s) : {indicateur.contact_arhives}
								</li>
							</ul>
						</div>
					</CardHome>
					<CardHome
						color='black'
						className='grey-color'
						rowStart={3}
						colStart={1}>
						<h1 style={{ fontSize: '30px' }} className='font-medium uppercase'>
							<a href='/mes-actus'>Mes actualités</a>
						</h1>
						<div className='divide-dashed  divide-y divide-gray-400 '>
							{news &&
								news.map((item, key) => <GlobalNews news={item} key={key} />)}
						</div>
					</CardHome>
					<CardHome
						className='default-color rounded-md flex items-start justify-center p-2'
						rowStart={3}
						colStart={1}
						photo='/images/background-home/suivi_dossier.JPG'>
						<h1 style={{ fontSize: '30px' }} className='font-medium uppercase'>
							<a href='/mes-suivis'>Suivi de mes dossiers</a>
						</h1>
						<Badge bgColor={'bg-red-700'} textColor={'text-white'}>
							{indicateur.folder}
						</Badge>
					</CardHome>
					<CardHome
						color='black'
						className='grey-color flex justify-center items-center'
						rowStart={2}
						colStart={1}>
						<a href='/mes-exports' className='w-1/2'>
							<span
								style={{ fontSize: '30px' }}
								className='font-medium uppercase text-center'>
								Gerer mes exports
							</span>
						</a>
					</CardHome>
					<CardHome className='blue-color' rowStart={1} colStart={2}>
						<h1 style={{ fontSize: '30px' }} className='font-medium uppercase'>
							<a href='/mes-estimations'>Mes estimations</a>
						</h1>
						<div className='flex items-center justify-left h-2/3'>
							<ul className='list-none'>
								<li className='p-2 text-left'>
									Estimation(s) non traitée(s) :
									<Badge bgColor={'bg-red-700'} textColor={'text-white'}>
										{indicateur.estimation}
									</Badge>
								</li>
								<li className='p-2 text-left'>
									Estimation(s) totales : {indicateur.all_estimation}{' '}
								</li>
							</ul>
						</div>
					</CardHome>
					<CardHome className='default-color' rowStart={1} colStart={2}>
						<h1 style={{ fontSize: '30px' }} className='font-medium uppercase'>
							<a href='/mes-rapprochements'>Mes rapprochements</a>
						</h1>
						<div className='flex items-center justify-left h-2/3'>
							<ul className='list-none'>
								<li className='p-2 text-left'>
									{' '}
									Nouveau(x) :
									<Badge bgColor={'bg-red-700'} textColor={'text-white'}>
										{indicateur.rapprochement}
									</Badge>
								</li>
								<li className='p-2 text-left'>
									Total rapprochements : {indicateur.all_rapprochement}
								</li>
							</ul>
						</div>
					</CardHome>
					<CardHome
						color='black'
						className='flex justify-center gap-1'
						rowStart={1}
						colStart={1}>
						<div className='rounded-md flex items-center justify-center default-color w-1/2 '>
							<a href='/agenda'>
								<img
									src='/images/icons/calendar-2.png'
									alt='Ordre du jour png'
									className='w-1/4 sm:w-4/5 mx-auto'
								/>
							</a>
						</div>
						<div className='rounded-md default-color w-1/2  h-full'></div>
					</CardHome>
				</div>

				{isLoading && <GeneralSpinner />}
			</>
		)) || <GeneralSpinner />
	);
}
