import { BlocCard } from '../../components/generals/card/bloc';

export const Formation = () => {
	return (
		<>
			<div className='flex flex-wrap justify-center items-center mb-8'>
				<div className='w-full bg-white'>
					<img
						className='w-full object-cover h-[700px]'
						alt='Ma formation'
						src='/images/9051320.jpg'
					/>
				</div>
			</div>
			<div className='flex flex-wrap justify-between items-center w-full gap-1'>
				<BlocCard
					blocTitle={'Mon accès formation'}
					link={'https://formation.mapimimmo.fr'}
					className={'w-full sm:w-1/5'}
				/>
				<BlocCard
					blocTitle={'Attestations'}
					link={'attestation'}
					className={'w-full sm:w-1/5'}
				/>
			</div>
		</>
	);
};
