import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isValidJSON } from '../../../../../helpers/attributes';

export const PricingInputUpdate = ({
	style,
	element,
	errors,
	register,
	control,
	formState,
	dataUpdate,
	setValue
}) => {
	const [initialRender, setInitialRender] = useState(0);

	// Mettre à jour les champs de saisie avec les valeurs de dataUpdate

	// Retourne les éléments associés
	const elementsData = useCallback(() => {
		return element.elements.map((elem) =>
			document.querySelector(`input[name=${elem.name}]`)
		);
	}, [element.elements]);

	const [publicPrice, vendorPrice, montant, percent] = elementsData();

	const calculate = (e) => {
		e.preventDefault();

		const publicPriceValue = parseInt(publicPrice.value || 0);
		const vendorPriceValue = parseInt(vendorPrice.value || 0);
		const montantValue = parseInt(montant.value || 0);
		const percentValue = parseFloat(percent.value || 0);

		if (publicPriceValue !== 0 && vendorPriceValue !== 0) {
			montant.value = parseInt(publicPriceValue) - parseInt(vendorPrice.value);
			setValue(montant.name, montant.value);
			percent.value = parseFloat(
				parseInt(montant.value) / parseInt(vendorPrice.value)
			).toFixed(3);
			setValue(percent.name, percent.value);
		} else if (publicPriceValue !== 0 && montantValue !== 0) {
			vendorPrice.value = parseInt(publicPriceValue) - parseInt(montantValue);
			setValue(vendorPrice.name, vendorPrice.value);
			percent.value = parseFloat(montant.value / vendorPrice.value).toFixed(3);
			setValue(percent.name, percent.value);
		} else if (vendorPriceValue !== 0 && montantValue !== 0) {
			publicPrice.value = parseInt(vendorPriceValue) + parseInt(montantValue);
			setValue(publicPrice.name, publicPrice.value);
		} else if (vendorPriceValue !== 0 && percentValue !== 0.0) {
			montant.value = parseInt(vendorPriceValue) * parseFloat(percentValue);
			setValue(montant.name, montant.value);
			publicPrice.value = parseInt(montant.value) + parseInt(vendorPriceValue);
			setValue(publicPrice.name, publicPrice.value);
		} else if (publicPriceValue !== 0 && percentValue !== 0.0) {
			montant.value = publicPriceValue * percentValue;
			setValue(montant.name, montant.value);
			vendorPrice.value = parseInt(publicPriceValue) - parseInt(montant.value);
			setValue(vendorPrice.name, vendorPrice.value);
		}
	};
	const reinitialized = (e) => {
		e.preventDefault();

		publicPrice.value = 0;
		vendorPrice.value = 0;
		montant.value = 0;
		percent.value = 0;
	};

	useEffect(() => {}, [initialRender]);

	useEffect(() => {
		element.elements.forEach((elm) => {
			if (elm.tagName) {
				let value = dataUpdate;
				elm.tagName.split('.').forEach((item) => {
					if (isValidJSON(value[item])) {
						let data = JSON.parse(value[item]);
						if (Array.isArray(data)) value = data[0];
						else value = data;
					} else {
						value = Array.isArray(value[item]) ? value[item][0] : value[item];
					}
				});

				if (elm.name === 'percent') {
					// console.log(value);
					// value = (value / 100).toFixed(3);
				}

				setValue(elm.name, value, {
					shouldTouch: true,
					shouldDirty: true
				});
			}
		});
		if (initialRender === 0) setInitialRender(1);
		if (initialRender === 1) setInitialRender(2);
	}, [initialRender]);

	return (
		<div className='p-0 m-0 w-full'>
			{element.elements.map((elem, index) => {
				return (
					<div key={index}>
						<label htmlFor={elem.name}>{elem.title} (*)</label>
						<input
							id={elem.name}
							step={0.1}
							type={elem.type}
							className={style.simple__input}
							defaultValue={dataUpdate[elem.name] || 0}
							{...register(elem.name, {
								...elem.validation
							})}
						/>
					</div>
				);
			})}
			<button
				onClick={calculate}
				type='button'
				className='!px-4 !py-2 !text-white blue-color-i !rounded-lg !mr-4'>
				CALCULER AUTOMATIQUEMENT
			</button>
			<button
				onClick={reinitialized}
				type='button'
				className='!px-4 !py-2 !text-white blue-color-i !rounded-lg'>
				REINITIALISER
			</button>
		</div>
	);
};
