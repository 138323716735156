import { useEffect, useState } from 'react';
import { Model1 } from './Model1';
import { Model2 } from './Model2';
import { Model3 } from './Model3';
import { Model4 } from './Model4';
import { Model5 } from './Model5';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { GeneralSpinner } from '../../../../components/commons/general-spinner';
import { useLocation } from 'react-router-dom';
import { Breadcumbs } from '../../../../components/generals/headers/breadcumbs';
import { createPortal } from 'react-dom';
import domtoimage from 'dom-to-image';

export const Model = () => {
	const [isDownloading, setIsDownloading] = useState(false);
	const location = useLocation();
	const contentId = 'content-model';
	const { bien, model } = location.state;
	const generatePDF = async () => {
		setIsDownloading(true);

		setTimeout(async () => {
			const element = document.getElementById(contentId);
			element.style.backgroundColor = '#ffffff';
			domtoimage.toJpeg(element, { quality: 1 }).then((dataUrl) => {
				setIsDownloading(false);
				const orientation = model.disposition;
				const pdfWidth = orientation === 'portrait' ? 210 : 297; // Largeur A4 en mm
				const pdfHeight = orientation === 'portrait' ? 297 : 210; // Hauteur A4 en mm

				const pdf = new jsPDF({
					orientation: orientation,
					unit: 'mm',
					format: 'a4'
				});
				pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
				pdf.save(`${model.title}.pdf`);
			});
		}, 2000);
	};

	useEffect(() => {}, []);

	return (
		<>
			<Breadcumbs title={"Modèle d'impression"} />
			<div className='mb-8'>
				<button
					className='blue-color text-white uppercase font-bold px-4 py-2 rounded-xl'
					onClick={generatePDF}>
					Imprimer
				</button>
			</div>

			<div id='content-model' className='p-5 text-2xl'>
				{model.name === 'model1' && (
					<Model1 bien={bien} is_downloading={isDownloading} />
				)}
				{model.name === 'model2' && (
					<Model2 bien={bien} is_downloading={isDownloading} />
				)}
				{model.name === 'model3' && (
					<Model3 bien={bien} is_downloading={isDownloading} />
				)}
				{model.name === 'model4' && (
					<Model4 bien={bien} is_downloading={isDownloading} />
				)}
				{model.name === 'model5' && (
					<Model5 bien={bien} is_downloading={isDownloading} />
				)}
			</div>
			{isDownloading && <GeneralSpinner />}
		</>
	);
};
