import { IS_APPART } from '../../../../helpers/forms/type';
import {
	coverImageFromProxy,
	imageFromProxy,
	slideImageFromProxy
} from '../../../../helpers/image.source.';
import { getStorageUser } from '../../../../service/localStorage';
import { Dpe } from '../dpe/Dpe';
import { Pricing } from '../dpe/Pricing';
import { useEffect } from 'react';
import { useState } from 'react';

export const Model5 = ({ bien, is_downloading }) => {
	return (
		<div className={`w-full`}>
			<div className='flex justify-between mt-10'>
				<div className='w-full font-bold'>
					{bien.advertisement.title} - {bien.sector.public_country} (
					{bien.sector.public_zap})
				</div>
				<div className='w-1/4 text-right'>
					<Pricing bien={bien} />
				</div>
			</div>
			<div className='flex items-start justify-between flex-wrap  my-4'>
				<div className={`w-[70%] flex items-center relative h-[800px]`}>
					<div className='w-full bg-white/70 p-1 flex items-start gap-2 flex-wrap justify-between absolute bottom-0 right-0 pt-6'>
						<div className='flex flex-wrap items-center gap-4 ml-4 w-1/4'>
							<small className='text-xl font-bold rounded flex gap-1 items-center justify-center'>
								<img className='w-7' src='/images/impressions/measure.png' />
								{bien.land_area} m<sup>2</sup>
							</small>

							{IS_APPART.includes(
								bien.classification_estate.designation.toLowerCase()
							) && (
								<>
									<small className='text-xl font-bold rounded flex gap-1 items-center justify-center'>
										<img
											className='w-7'
											src='/images/impressions/king-size.png'
										/>
										{bien.number_bedroom}
									</small>
									{bien.interior_detail?.nbOfBathroom && (
										<small className='text-xl font-bold rounded flex gap-1 items-center justify-center'>
											<img
												className='w-7'
												src='/images/impressions/shower.png'
											/>
											{bien.interior_detail.nbOfBathroom}
										</small>
									)}
								</>
							)}
						</div>

						<div className='w-2/3 flex min-h-[100px] items-stretch relative mb-10'>
							<div className='flex w-full justify-end'>
								<div className='w-1/3 flex items-center justify-center mt-4'>
									<Dpe5 bien={bien} is_downloading={is_downloading} />
								</div>
								<div className='w-1/3 flex items-center justify-center mt-4'>
									<Ges5 bien={bien} is_downloading={is_downloading} />
								</div>
							</div>
						</div>
					</div>
					<img
						src={coverImageFromProxy(bien.photos.photos_couvert[0].photo)}
						alt='Cover'
						className={`w-full h-full object-cover `}
					/>
				</div>
				<div className={`text-md mt-10 w-[30%]`}>
					<div className='w-full pl-6 break-words text-[16px] text-justify'>
						{bien.advertisement.description}
					</div>
				</div>

				<div className='w-full flex'>
					<div className={`w-[70%] gap-4 flex items-center justify-start`}>
						{bien.photos.photos_slide &&
							bien.photos.photos_slide.length != 0 &&
							[0, 1, 2].map((item, index) => (
								<div key={index} className='w-1/3'>
									{bien.photos.photos_slide[index] && (
										<img
											src={slideImageFromProxy(
												bien.photos.photos_slide[index].photo
											)}
											alt={'Slide ' + index}
											className={`object-cover ${'w-full h-full'}`}
										/>
									)}
								</div>
							))}
					</div>

					<div className='w-[30%] flex items-center'>
						<div>
							<img
								src={
									getStorageUser().user.agency.agency_logo
										? imageFromProxy(getStorageUser().user.agency.agency_logo)
										: '/images/LOGO_MAPIM_IMMO_BLANC.jpg'
								}
								className='w-1/2 mx-auto'
								alt='Model1 du bien'
							/>
							<div className='text-center text-[14px]'>
								<small>{getStorageUser().user.name}</small>
								<br />
								<small>{getStorageUser().user.email}</small>
								<br />
								<small>{getStorageUser().user.phone}</small>
								<br />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const DPE_COLOR = {
	A: { class: 'mt-[0%]', name: 'A', downloading_class: 'mt-[5%]' },
	B: { class: 'mt-[4.5%]', name: 'B', downloading_class: 'mt-[9%]' },
	C: { class: 'mt-[10.5%]', name: 'C', downloading_class: 'mt-[16%]' },
	D: { class: 'mt-[19.5%]', name: 'D', downloading_class: 'mt-[25%]' },
	E: { class: 'mt-[27.5%]', name: 'E', downloading_class: 'mt-[32%]' },
	F: { class: 'mt-[35%]', name: 'F', downloading_class: 'mt-[40%]' },
	G: { class: 'mt-[42.5%]', name: 'G', downloading_class: 'mt-[48%]' }
};

const Dpe5 = ({ bien, is_downloading }) => {
	const calculateDPE = () => {
		const consommation = parseInt(bien.diagnostic.dpe_consommation);
		const ges = parseInt(bien.diagnostic.dpe_ges);
		let dpeColor;
		if ((0 <= ges && 6 >= ges) || (consommation <= 69 && 0 <= consommation)) {
			dpeColor = DPE_COLOR.A;
		}
		if (
			(7 <= ges && 10 >= ges) ||
			(consommation <= 109 && 70 <= consommation)
		) {
			dpeColor = DPE_COLOR.B;
		}
		if (
			(11 <= ges && 29 >= ges) ||
			(consommation <= 179 && 110 <= consommation)
		) {
			dpeColor = DPE_COLOR.C;
		}
		if (
			(30 <= ges && 49 >= ges) ||
			(consommation <= 249 && 180 <= consommation)
		) {
			dpeColor = DPE_COLOR.D;
		}
		if (
			(50 <= ges && 69 >= ges) ||
			(consommation <= 329 && 250 <= consommation)
		) {
			dpeColor = DPE_COLOR.E;
		}
		if (
			(70 <= ges && 99 >= ges) ||
			(consommation <= 429 && 330 <= consommation)
		) {
			dpeColor = DPE_COLOR.F;
		}
		if (100 <= ges || 430 <= consommation) {
			dpeColor = DPE_COLOR.G;
		}

		return dpeColor;
	};

	const [currentGes, setCurrentGes] = useState();

	useEffect(() => {
		const ges = calculateDPE();
		setCurrentGes(ges);
	}, []);

	return (
		<>
			{(bien.diagnostic.dpes === 'DPE Vièrge' && (
				<img
					className='w-1/3'
					src='/images/logo_DPE_vierge.png'
					alt='DPE Vierge'
				/>
			)) ||
				(currentGes && (
					<>
						{
							<div
								className='w-full flex items-stretch min-h-[130px]  -mr-28'
								style={{
									backgroundSize: '100%',
									backgroundPosition: '0px 0',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url('/images/DPE/${currentGes.name}.png')`
								}}>
								<div
									className={`${currentGes.downloading_class}  ${
										bien.diagnostic.dpe_ges < 10 ||
										bien.diagnostic.dpe_consommation < 10
											? 'pl-6'
											: 'pl-3'
									} flex gap-[1.2em] items-start text-sm font-bold`}>
									<div>{bien.diagnostic.dpe_consommation}</div>
									<div>{bien.diagnostic.dpe_ges}</div>
								</div>
							</div>
						}
					</>
				))}
		</>
	);
};

const GES_COLOR = {
	A: {
		class: 'mt-[3.5%] mr-[35%]',
		name: 'A',
		downloading_class: 'mt-[7%] mr-[35%]'
	},
	B: {
		class: 'mt-[4.5%] mr-[32%]',
		name: 'B',
		downloading_class: 'mt-[12%] mr-[32%]'
	},
	C: {
		class: 'mt-[14.5%] mr-[30%]',
		name: 'C',
		downloading_class: 'mt-[17%] mr-[30%]'
	},
	D: {
		class: 'mt-[20.5%] mr-[25%]',
		name: 'D',
		downloading_class: 'mt-[23%] mr-[25%]'
	},
	E: {
		class: 'mt-[25%] mr-[19.5%]',
		name: 'E',
		downloading_class: 'mt-[29%] mr-[19.5%]'
	},
	F: {
		class: 'mt-[30%] mr-[15.5%]',
		name: 'F',
		downloading_class: 'mt-[34%] mr-[15.5%]'
	},
	G: {
		class: 'mt-[36.5%] mr-[11.5%]',
		name: 'G',
		downloading_class: 'mt-[38.5%] mr-[9.5%]'
	}
};

const Ges5 = ({ bien, is_downloading }) => {
	const calculateDPE = () => {
		const ges = parseInt(bien.diagnostic.dpe_ges);
		let dpeColor;
		if (0 <= ges && 6 >= ges) {
			dpeColor = GES_COLOR.A;
		}
		if (7 <= ges && 10 >= ges) {
			dpeColor = GES_COLOR.B;
		}
		if (11 <= ges && 29 >= ges) {
			dpeColor = GES_COLOR.C;
		}
		if (30 <= ges && 49 >= ges) {
			dpeColor = GES_COLOR.D;
		}
		if (50 <= ges && 69 >= ges) {
			dpeColor = GES_COLOR.E;
		}
		if (70 <= ges && 99 >= ges) {
			dpeColor = GES_COLOR.F;
		}
		if (100 <= ges) {
			dpeColor = GES_COLOR.G;
		}

		return dpeColor;
	};

	const [currentGes, setCurrentGes] = useState();

	useEffect(() => {
		const ges = calculateDPE();
		setCurrentGes(ges);
	}, []);

	return (
		<>
			{(bien.diagnostic.dpes === 'DPE Vièrge' && (
				<img
					className='w-1/3'
					src='/images/logo_DPE_vierge.png'
					alt='DPE Vierge'
				/>
			)) ||
				(currentGes && (
					<>
						{
							<div
								className='w-full flex items-stretch min-h-[130px] -mr-8'
								style={{
									backgroundSize: '100%',
									backgroundPosition: '0px 5px',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url('/images/GES/${currentGes.name}.png')`
								}}>
								<div
									className={`${currentGes.downloading_class} w-full text-end text-[12px] font-bold`}>
									<div className='w-full'>{bien.diagnostic.dpe_ges}</div>
								</div>
							</div>
						}
					</>
				))}
		</>
	);
};
