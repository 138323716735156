import {
	coverImageFromProxy,
	slideImageFromProxy
} from '../../../../helpers/image.source.';
import { Dpe } from '../dpe/Dpe';
import { Pricing } from '../dpe/Pricing';
import { IS_APPART, TYPE_OFFRE } from '../../../../helpers/forms/type';
import { DpeSmall } from '../dpe/DpeSmall';

import { useEffect, useState } from 'react';

export const Model4 = ({ bien, is_downloading }) => {
	return (
		<>
			<div
				className='relative border-2 border-solid'
				style={{
					minHeight: '750px'
				}}>
				<img
					src={`${coverImageFromProxy(bien.photos.photos_couvert[0].photo)}`}
					alt=''
					className='w-full'
				/>

				{bien.photos.photos_slide.length != 0 && (
					<div className='text-white w-1/3 absolute top-0 right-0 bg-white p-[5px] flex items-center justify-center'>
						<img
							src={`${slideImageFromProxy(bien.photos.photos_slide[0].photo)}`}
							className='w-full h-auto'
							alt=''
						/>
					</div>
				)}

				<div className='w-4/5 absolute bottom-10 right-0 border border-gray z-20 flex items-stretch justify-center text-sm'>
					<div className='w-2/5 bg-[#343338]/60 px-4 py-4'>
						<h1 className='font-bold text-white'>
							{bien.advertisement.title} - {bien.sector.public_country} (
							{bien.sector.public_zap})
						</h1>
						<div className='text-white mt-10'>
							<Pricing bien={bien} />
						</div>
					</div>
					<div className='w-3/5 bg-white/70 p-1 flex items-start gap-8 flex-wrap justify-between pt-6'>
						<div className='flex flex-wrap items-center gap-8 ml-4'>
							<small className='text-xl font-bold rounded flex gap-1 items-center justify-center'>
								<img className='w-10' src='/images/impressions/measure.png' />
								{bien.land_area} m<sup>2</sup>
							</small>
							{IS_APPART.includes(
								bien.classification_estate.designation.toLowerCase()
							) && (
								<>
									<small className='text-xl font-bold rounded flex gap-1 items-center justify-center'>
										<img
											className='w-10'
											src='/images/impressions/king-size.png'
										/>
										{bien.number_bedroom}
									</small>
									{bien.interior_detail?.nbOfBathroom && (
										<small className='text-xl font-bold rounded flex gap-1 items-center justify-center'>
											<img
												className='w-10'
												src='/images/impressions/shower.png'
											/>
											{bien.interior_detail.nbOfBathroom}
										</small>
									)}
								</>
							)}
						</div>
						<div className='w-1/2 flex items-stretch'>
							<div className='w-1/2 flex items-center justify-center'>
								<Dpe4 bien={bien} is_downloading={is_downloading} />
							</div>
							<div className='w-1/2 flex items-center justify-center'>
								<Ges4 bien={bien} is_downloading={is_downloading} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const DPE_COLOR = {
	A: { class: 'mt-[0%]', name: 'A', downloading_class: 'mt-[3%]' },
	B: { class: 'mt-[8.5%]', name: 'B', downloading_class: 'mt-[8%]' },
	C: { class: 'mt-[20.5%]', name: 'C', downloading_class: 'mt-[15.5%]' },
	D: { class: 'mt-[32.5%]', name: 'D', downloading_class: 'mt-[23.5%]' },
	E: { class: 'mt-[42%]', name: 'E', downloading_class: 'mt-[31%]' },
	F: { class: 'mt-[55%]', name: 'F', downloading_class: 'mt-[38.5%]' },
	G: { class: 'mt-[64%]', name: 'G', downloading_class: 'mt-[46%]' }
};

const GES_COLOR = {
	A: {
		name: 'A',
		downloading_class: 'mt-[8.5%] mr-[35%]'
	},
	B: {
		name: 'B',
		downloading_class: 'mt-[14%] mr-[32%]'
	},
	C: {
		name: 'C',
		downloading_class: 'mt-[20%] mr-[28%]'
	},
	D: {
		name: 'D',
		downloading_class: 'mt-[24.5%] mr-[25%]'
	},
	E: {
		name: 'E',
		downloading_class: 'mt-[30%] mr-[19.5%]'
	},
	F: {
		name: 'F',
		downloading_class: 'mt-[35.5%] mr-[15.5%]'
	},
	G: {
		name: 'G',
		downloading_class: 'mt-[40.5%] mr-[11.5%]'
	}
};

export const Dpe4 = ({ bien, is_downloading }) => {
	const calculateDPE = () => {
		const consommation = parseInt(bien.diagnostic.dpe_consommation);
		const ges = parseInt(bien.diagnostic.dpe_ges);
		let dpeColor;

		if ((0 <= ges && 6 >= ges) || (consommation <= 69 && 0 <= consommation)) {
			dpeColor = DPE_COLOR.A;
		}
		if (
			(7 <= ges && 10 >= ges) ||
			(consommation <= 109 && 70 <= consommation)
		) {
			dpeColor = DPE_COLOR.B;
		}
		if (
			(11 <= ges && 29 >= ges) ||
			(consommation <= 179 && 110 <= consommation)
		) {
			dpeColor = DPE_COLOR.C;
		}
		if (
			(30 <= ges && 49 >= ges) ||
			(consommation <= 249 && 180 <= consommation)
		) {
			dpeColor = DPE_COLOR.D;
		}
		if (
			(50 <= ges && 69 >= ges) ||
			(consommation <= 329 && 250 <= consommation)
		) {
			dpeColor = DPE_COLOR.E;
		}
		if (
			(70 <= ges && 99 >= ges) ||
			(consommation <= 429 && 330 <= consommation)
		) {
			dpeColor = DPE_COLOR.F;
		}
		if (100 <= ges || 430 <= consommation) {
			dpeColor = DPE_COLOR.G;
		}

		return dpeColor;
	};

	const [currentGes, setCurrentGes] = useState();

	useEffect(() => {
		const ges = calculateDPE();
		setCurrentGes(ges);
	}, []);

	return (
		<>
			{(bien.diagnostic.dpes === 'DPE Vièrge' && (
				<img
					className='w-1/3'
					src='/images/logo_DPE_vierge.png'
					alt='DPE Vierge'
				/>
			)) ||
				(currentGes && (
					<>
						{
							<div
								className='w-full flex items-stretch min-h-[180px] -mr-28'
								style={{
									backgroundSize: '100%',
									backgroundPosition: '0px 0',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url('/images/DPE/${currentGes.name}.png')`
								}}>
								<div
									className={`${currentGes.downloading_class} pl-3 flex ${
										bien.diagnostic.dpe_ges < 10 ? 'gap-[1.8em]' : 'gap-[0.5em]'
									} items-start text-sm`}>
									<div>{bien.diagnostic.dpe_consommation}</div>
									<div>{bien.diagnostic.dpe_ges}</div>
								</div>
							</div>
						}
					</>
				))}
		</>
	);
};

export const Ges4 = ({ bien, is_downloading }) => {
	const calculateDPE = () => {
		const ges = parseInt(bien.diagnostic.dpe_ges);
		let dpeColor;
		if (0 <= ges && 6 >= ges) {
			dpeColor = GES_COLOR.A;
		}
		if (7 <= ges && 10 >= ges) {
			dpeColor = GES_COLOR.B;
		}
		if (11 <= ges && 29 >= ges) {
			dpeColor = GES_COLOR.C;
		}
		if (30 <= ges && 49 >= ges) {
			dpeColor = GES_COLOR.D;
		}
		if (50 <= ges && 69 >= ges) {
			dpeColor = GES_COLOR.E;
		}
		if (70 <= ges && 99 >= ges) {
			dpeColor = GES_COLOR.F;
		}
		if (100 <= ges) {
			dpeColor = GES_COLOR.G;
		}

		return dpeColor;
	};

	const [currentGes, setCurrentGes] = useState();

	useEffect(() => {
		const ges = calculateDPE();
		setCurrentGes(ges);
	}, []);

	return (
		<>
			{(bien.diagnostic.dpes === 'DPE Vièrge' && (
				<img
					className='w-1/3'
					src='/images/logo_DPE_vierge.png'
					alt='DPE Vierge'
				/>
			)) ||
				(currentGes && (
					<>
						{
							<div
								className='w-full flex items-stretch min-h-[170px] -mr-10'
								style={{
									backgroundSize: '100%',
									backgroundPosition: '0px 5px',
									backgroundRepeat: 'no-repeat',
									backgroundImage: `url('/images/GES/${currentGes.name}.png')`
								}}>
								<div
									className={`${currentGes.downloading_class} w-full text-end text-[10px]`}>
									<div className='w-full'>{bien.diagnostic.dpe_ges}</div>
								</div>
							</div>
						}
					</>
				))}
		</>
	);
};
