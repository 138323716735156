import { useEffect, useState } from 'react';
import { BackgroundColor } from '../../../../helpers/backgroundcolor';
import { ColorText } from './BienText';
import { useNavigate } from 'react-router-dom';
import {
	CIVILITY,
	PARSE_CONTACT_NAME
} from '../../../../helpers/optionsContact';

export const ContactText = (actus) => {
	const [contentText, setContentText] = useState();
	const navigate = useNavigate();

	useEffect(() => {
		const dataNew = actus.actus;

		switch (dataNew.action) {
			case 'created':
				var text = `Ajout du nouveau contact  ${
					CIVILITY[JSON.parse(dataNew.content).civility] ?? ''
				} ${PARSE_CONTACT_NAME(JSON.parse(dataNew.content))}  par ${
					dataNew.agent?.name
				}`;
				var bgColor = 'green';
				return setContentText({ text, bgColor });

			case 'associed':
				var bgColor = 'yellow';

				if (JSON.parse(dataNew.content).mandate.length === 0) {
					var text = `Association du contact   ${
						CIVILITY[JSON.parse(dataNew.content).civility] ?? ''
					} ${PARSE_CONTACT_NAME(JSON.parse(dataNew.content))}  par ${
						dataNew.agent?.name
					}`;
					setContentText({ text, bgColor });
				} else if (JSON.parse(dataNew.content).mandate[0].bien_id_bien) {
					var text = `Association du contact   ${
						CIVILITY[JSON.parse(dataNew.content).civility] ?? ''
					} ${PARSE_CONTACT_NAME(JSON.parse(dataNew.content))}  au bien n° ${
						JSON.parse(dataNew.content).mandate[0].num_mandat
					} `;
					setContentText({ text, bgColor });
				}
				break;

			case 'updated':
				var text = `Mise à jour du contact  ${
					CIVILITY[JSON.parse(dataNew.content).civility] ?? ''
				} ${PARSE_CONTACT_NAME(JSON.parse(dataNew.content))}  par ${
					dataNew.agent?.name
				}`;
				var bgColor = 'orange';
				return setContentText({ text, bgColor });

			case 'deleted':
				var text = `Desactivation du contact    ${
					CIVILITY[JSON.parse(dataNew.content).civility] ?? ''
				} ${PARSE_CONTACT_NAME(JSON.parse(dataNew.content))}  par ${
					dataNew.agent?.name
				}`;
				var bgColor = 'red';
				return setContentText({ text, bgColor });

			default:
				break;
		}
	}, []);

	return (
		contentText && (
			<div
				className='flex items-stretch justify-between py-2 mx-2'
				onClick={() =>
					navigate(
						'/mes-contacts/details-contact/' +
							JSON.parse(actus.actus.content).uuid
					)
				}>
				<div className='text-center'>{contentText.text}</div>
				<ColorText color={BackgroundColor(contentText.bgColor)} />
			</div>
		)
	);
};
