import React, { useEffect, useState } from 'react';
import BienService from '../../service/bien/BienService';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { BienSection } from '../../components/generals/biens/section/bien.section';
import { BienInfoSection } from './details/BienInfoSection';
import StatusSection from '../../components/generals/biens/section/status.section';
import { GeneralSpinner } from '../../components/commons/general-spinner';

function BienDetailsPage() {
	const location = useLocation();
	const [bien, setBien] = useState();
	const { bienId } = useParams();
	useEffect(() => {
		if (!bien)
			BienService.getBienById(bienId, 'detail')
				.then((res) => {
					setBien(res.data);
				})
				.catch(() => {});
	}, [bienId, bien, location]);

	return (
		(bien && (
			<>
				<div className=''>
					<BienSection bien={bien} />
				</div>

				<div className='border-2 border-solid border-[#2d5c6a]'>
					<StatusSection location={location} bien={bien} />

					<BienInfoSection bien={bien} />
				</div>
			</>
		)) || <GeneralSpinner />
	);
}

export default BienDetailsPage;
